/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Title, ColumnCover, ColumnDiv, Subtitle, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-p775ns --center --parallax" style={{"paddingTop":0,"marginBottom":0,"paddingBottom":19}} name={"sef2q21532j"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":360,"marginTop":3,"paddingTop":18}} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"}>
              </Image>

              <Text className="text-box ff--3 fs--20" style={{"maxWidth":654,"paddingTop":24}} content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">potápěčské centrum</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}} name={"uf7tgw2tos"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style4 title-box--center ff--3 fs--22" content={"AKTUALITY"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"urh153nw77g"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--30" content={"Trvalá změna otevírací doby:"}>
              </Title>

              <Text className="text-box ff--3 fs--24" style={{"maxWidth":650,"marginTop":25}} content={"Po, St, Pá: 10 - 15<br>Út, Čt: - 10 - 17<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column layout={"l13"} style={{"paddingTop":28,"paddingBottom":32}} name={"99cxqbvm6ut"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":1132}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":532,"marginBottom":0}}>
              
              <Title className="title-box ff--3 fs--48" style={{"maxWidth":533,"paddingRight":0}} content={"CAVE DIVING MANUAL"}>
              </Title>

              <Title className="title-box ff--3" content={"<span style=\"color: rgb(199, 18, 18);\">Daniel Hutňan</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--20" content={"<span style=\"color: rgb(0, 0, 0);\">Dlouho očekávané druhé vydání Cave Diving Manualu je tady! Objednávejte na<a href=\"mailto:orca@orcadiving.cz\"> orca@orcadiving.cz</a> Cena 1000,-</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/ed977931edb540e5951b9605f3eff5bf_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/13078/ed977931edb540e5951b9605f3eff5bf_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":20,"paddingBottom":29}} name={"ummwsmzibol"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":1079}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13078/da80aa05b05f4631a5e22825924a0661_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/da80aa05b05f4631a5e22825924a0661_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/da80aa05b05f4631a5e22825924a0661_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/da80aa05b05f4631a5e22825924a0661_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="flex--center" style={{"paddingTop":0}}>
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"/safarivraky\"><span style=\"font-weight: 700;\">Egypt</span><br>vrakové safari</a><br>"}>
                    </Subtitle>

                    <Text className="text-box ff--3" style={{"marginTop":9,"paddingBottom":89}} content={"<span style=\"color: rgb(164, 19, 19); font-weight: bold;\">30. 5. - 6. 6. 2025</span><br>"}>
                    </Text>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13078/7b665678d2264de4a9432ea7f037ffb4_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/7b665678d2264de4a9432ea7f037ffb4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7b665678d2264de4a9432ea7f037ffb4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7b665678d2264de4a9432ea7f037ffb4_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="flex--center" style={{"paddingTop":0}}>
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"/ciernavoda\"><span style=\"font-weight: 700;\">Čierna Voda<br></span>slovenští kapři</a><br>"}>
                    </Subtitle>

                    <Text className="text-box ff--3" style={{"marginTop":9,"paddingBottom":89}} content={"<span style=\"color: rgb(164, 19, 19); font-weight: bold;\">7. - 10. srpna 2025</span><br>"}>
                    </Text>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":527}} src={"https://cdn.swbpg.com/o/13078/8b9f575358694703ad5f2d645e048df5.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} lightbox={false}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="flex--center" style={{"paddingTop":0}}>
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"/egypt-safari\"><span style=\"font-weight: 700;\">Egypt - safari<br></span>severní cesta</a><br>"}>
                    </Subtitle>

                    <Text className="text-box ff--3" style={{"marginTop":9,"paddingBottom":89}} content={"<span style=\"color: rgb(164, 19, 19); font-weight: bold;\">29. 11. - 6. 12. 2025</span><br>"}>
                    </Text>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingBottom":0,"paddingTop":0}} name={"32y6n1hv0im"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"<span style=\"font-family: Muli, sans-serif; font-size: 26px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 700; letter-spacing: -0.52px;\">Kurz potápění jako dárek</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0); font-family: &quot;IBM Plex Sans&quot;, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.32px;\">Dárkový poukaz s textem dle Vašeho přání v elektronické / zalaminované formě.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":5,"paddingBottom":8}} name={"hzs9xu7bdes"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":415}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"tk2lpi59mil"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column layout={"l13"} style={{"paddingTop":40,"paddingBottom":48}} name={"08rft9k47wl"}>
        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":0,"paddingBottom":3,"backgroundColor":"rgba(165,190,203,1)"}} name={"olewgqfz6kh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3" content={"Kurzy potápění"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":11,"paddingBottom":0}} name={"e6k88ofbjpf"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":583}}>
              
              <Image style={{"maxWidth":76}} src={"https://cdn.swbpg.com/t/13078/575a95daa112484f80732573ac5d72fb_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/575a95daa112484f80732573ac5d72fb_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box text-box--center" style={{"maxWidth":611,"marginTop":8,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"font-family: &quot;IBM Plex Sans&quot;, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.32px; text-align: left;\">Nově je možné absolvovat kurz pro začátečníky s tlumočením do znakového jazyka.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":17,"paddingBottom":8}} name={"kgitiz2ceo"}>
          
          <ColumnWrap className="column__flex --left el--3" style={{"maxWidth":827}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" style={{"maxWidth":655}} src={"https://cdn.swbpg.com/t/13078/168e8afcf3c94afdaff32c61d7c28154_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/168e8afcf3c94afdaff32c61d7c28154_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/168e8afcf3c94afdaff32c61d7c28154_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box ff--3 fs--26" style={{"marginTop":10}} content={"Zkušební ponor"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467,"marginTop":14}} content={"<span style=\"color: rgb(0, 0, 0);\">Exkurze s přístrojem v bazénu Zelený pruh je možná každé úterý (září - červen) od 20 do 21:30 hodin. V případě zakoupení kurzu pro začátečníky Vám bude cena exkurze odečtena od ceny kurzu.</span><br><br>Nejbližší termín: <span style=\"color: rgb(0, 0, 0);\">kdykoli po domluvě s instruktorem</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 ff--3" style={{"marginTop":13}} use={"page"} href={"/kurzy#ponornazkousku"} content={"info"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" style={{"maxWidth":655}} src={"https://cdn.swbpg.com/t/13078/d9ed3ea97e804943875bccc615eeda8e_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/d9ed3ea97e804943875bccc615eeda8e_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box ff--3 fs--26" style={{"marginTop":10}} content={"Kurz pro začátečníky"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467,"marginTop":14}} content={"<span style=\"color: rgb(0, 0, 0);\">Rekreační potápění s přístrojem v systémech IANTD (Open Water Diver), nebo CMAS (P*), do hloubky 18 m / 20 m.<br></span><br>Nejbližší termín: <span style=\"color: rgb(0, 0, 0);\">1. 4. 2025</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 ff--3" style={{"marginTop":12}} use={"page"} href={"/kurzy#owd"} content={"info"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" style={{"maxWidth":655}} src={"https://cdn.swbpg.com/t/13078/b6e58dd7a42e46408045b4498907c78a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/b6e58dd7a42e46408045b4498907c78a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/b6e58dd7a42e46408045b4498907c78a_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box ff--3 fs--26" style={{"marginTop":10}} content={"Kurz pro pokročilé"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467,"marginTop":14}} content={"<span style=\"color: rgb(0, 0, 0);\">Potápění s přístrojem do hloubky 30 m v systémech IANTD (Advanced Open Water Diver), nebo CMAS (P**).</span><br><br>Nejbližší termín:<span style=\"color: rgb(2, 2, 2);\">&nbsp;září</span>&nbsp;<span style=\"color: rgb(0, 0, 0);\">2025</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 ff--3" style={{"marginTop":10}} use={"page"} href={"/kurzy#aowd"} content={"info"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"bvn821efk3j"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":7,"paddingBottom":4,"backgroundColor":"rgba(165,190,203,1)"}} name={"olewgqfz6kh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--center ff--3" content={"Cestování"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":24,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --left el--4" style={{"maxWidth":1319,"paddingLeft":0,"marginBottom":10,"paddingRight":0,"paddingBottom":0}} columns={"4"}>
            
            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/safarivraky\">Egypt<br>safari<br>severní vraky</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/5beb77def6e343ff97e9ff4c6645c4f9_s=660x_.jpg"} use={"page"} href={"/safarivraky"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/5beb77def6e343ff97e9ff4c6645c4f9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/5beb77def6e343ff97e9ff4c6645c4f9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/5beb77def6e343ff97e9ff4c6645c4f9_s=860x_.jpg 860w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">30. 5. - 6. 6. </span><br><br><br>&nbsp;Cena:&nbsp;<span style=\"color: rgb(2, 2, 2);\">23 500 Kč + letenka<br></span><span style=\"color: rgb(0, 0, 0);\"><br>Krásné vraky severní části Rudého moře z nové lodi ICON. Akce klubu Orca a TRIM Academy.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/dahab\">Egypt<br>Dahab<br><span style=\"color: rgb(42, 158, 182);\">připravujeme</span></a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/44aeb037c7b24b7a81be4a2423d2e874_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/44aeb037c7b24b7a81be4a2423d2e874_s=350x_.png 350w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">23. - 30. 5.</span><br><br>&nbsp;Cena nepotápěči: <span style=\"color: rgb(0, 0, 0);\">19 200 Kč</span><br>Cena potápěči: <span style=\"color: rgb(0, 0, 0);\">25&nbsp;500 Kč<br>+ letenka<br><br>Dahab -&nbsp;meka potápěčů, freediverů a kite-surferů.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/pagkustici\">Chorvatsko<br>ostrov Pag<br>Kustići</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/d1303ac6f63241808484209e66b7a072_s=350x_.jpg"} use={"page"} href={"/pagkustici"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/d1303ac6f63241808484209e66b7a072_s=350x_.jpg 350w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Jarní termín 2024<br><span style=\"color: rgb(5, 5, 5);\">21. - 25. 5.<br></span>Poslední 2 místa<br><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">6 850 Kč</span><br><br><span style=\"color: rgb(0, 0, 0);\">Potápění v Jaderském moři na ostrově PAG s možností dokončit praktickou část všech kurzů potápění.</span><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/ciernavoda\">Slovensko<br>Čierna voda<br></a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/a21318676cf34c268e31630942970ec3_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/a21318676cf34c268e31630942970ec3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/a21318676cf34c268e31630942970ec3_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">7. - 10. 8.</span><br><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">2 550 Kč</span><br><br><span style=\"color: rgb(0, 0, 0);\">Bohatě zarybněné jezero s vyvázanými atrakcemi a okruhy pro OWD, AOWD i Deep divery. Krásná klubová akce, grilování a pohoda u vody.<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/egypt-safari\"><span style=\"font-weight: bold;\">Egypt</span><br>safari <br>severní cesta</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/df44dff4fe044c70bacd80066e6e05a3_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/df44dff4fe044c70bacd80066e6e05a3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/df44dff4fe044c70bacd80066e6e05a3_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">30. 11. - 7. 12.</span><br><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">38 750 Kč</span><br><br><span style=\"color: rgb(0, 0, 0);\">Ostrovy a korálové útesy v okolí Hurghady a Sinajského poloostrova.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}